/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body .p-inputtext{
  width: 324px;
}

.navItemList{
  display: flex;
}

.p-card{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  
}

.p-card-title{
    size: 24px !important;
    margin-bottom: 18px;
  }

.p-button{
  margin-right: 12px;
}

.preview-label{
  font-size: 16px;
  font-weight: bold;
}

.preview-card{
  padding: 20px;
}

.errorText{
  color: red;
}

.p-datatable-header{
  min-width: 1105px;
}

.p-datatable-wrapper{
  min-width: 1105px;
}

.p-datatable{
  td{
    overflow-wrap: break-word;
  }
}

.required{
  color: red;
  font-weight: bold;
  margin-left: 8px;
}

.guideText{
      font-size: small;
    margin-top: 10px;
    font-style: italic;
}